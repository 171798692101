import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import axios from 'axios';

import {
  priceFormat,
  getLatLong,
  formatForGeocoding,
  httpToHttps,
} from '../helpers/propertyHelpers';

import PropertyTemplate from '../components/PropertyRentalTemplate';

function setPropertyData(propertyData, location) {
  const property = {
    address: massageData(propertyData.address),
    adpic_link: massageData(propertyData.adpic_link),
    // air_conditioner: massageData(propertyData.air_conditioner),
    // allow_animals: massageData(propertyData.allow_animals),
    allow_animals: 'Negotiable',
    // allow_smokers: massageData(propertyData.allow_smokers),
    app_enable: massageData(propertyData.app_enable),
    app_link: massageData(propertyData.app_link),
    bathrooms: massageData(propertyData.bathrooms, false),
    bedrooms: massageData(propertyData.bedrooms, false),
    city: massageData(propertyData.city),
    class_ad: massageData(propertyData.class_ad),
    deposit: priceFormat(massageData(propertyData.deposit), undefined, true),
    description: massageData(propertyData.description),
    // fiftyfive_plus: massageData(propertyData.fiftyfive_plus),
    // garage_carport: massageData(propertyData.garage_carport),
    images: massageDataImages(propertyData.images),
    thumbs: massageDataImages(propertyData.images, false),
    lat:
      location && location.results[0]
        ? location.results[0].geometry.location.lat
        : null,
    lng:
      location && location.results[0]
        ? location.results[0].geometry.location.lng
        : null,
    // lease: massageData(propertyData.lease),
    leaseterms: massageData(propertyData.leaseterms),
    // masterunit: massageData(propertyData.masterunit),
    // multiplex: massageData(propertyData.multiplex),
    nickname: massageData(propertyData.nickname),
    // property_id: massageData(propertyData.property_id),
    rent: massageData(propertyData.rent),
    // rental_assistance: massageData(propertyData.rental_assistance),
    sqft: massageData(propertyData.sqft),
    state: massageData(propertyData.state),
    type: massageData(propertyData.type),
    updated: massageData(propertyData.updated),
    washer_dryer: massageData(propertyData.washer_dryer),
    washer_dryer_hookups:
      propertyData.washer_dryer === '0' &&
      massageData(propertyData.washer_dryer_hookups),
    // wheelchair_access: massageData(propertyData.wheelchair_access),
    yearbuilt: massageData(propertyData.yearbuilt),
    zip: massageData(propertyData.zip),
  };

  return property;
}

const massageData = (data, numbersAreTrueFalse = true) => {
  let returnData;
  if (data) {
    if (numbersAreTrueFalse) {
      if (data === '0') {
        returnData = 'No';
      } else if (data === '1') {
        returnData = 'Yes';
      } else {
        returnData = data;
      }
    } else {
      returnData = data;
    }
  } else {
    return null;
  }

  return returnData;
};

const massageDataImages = (images, isMainImg = true) => {
  let imageArray = [];
  if (images) {
    images.map((image, i) => {
      if (isMainImg) {
        imageArray.push(httpToHttps(image.imagelink));
      } else {
        imageArray.push(httpToHttps(image.thumblink));
      }
    });
  } else {
    imageArray = null;
  }

  return imageArray;
};

export const query = graphql`
  query {
    rentec {
      properties {
        address
        adpic_link
        air_conditioner
        allow_animals
        allow_smokers
        app_enable
        app_link
        bathrooms
        bedrooms
        city
        class_ad
        deposit
        description
        fiftyfive_plus
        garage_carport
        images {
          imagelink
          thumblink
        }
        lease
        leaseterms
        masterunit
        multiplex
        nickname
        property_id
        rent
        rental_assistance
        sqft
        state
        type
        updated
        washer_dryer
        washer_dryer_hookups
        wheelchair_access
        yearbuilt
        zip
      }
    }
  }
`;

const PropertyWrap = styled.section``;

const Property = props => {
  // This returns the single array object matching the id proded in the page context
  const propertyArr = props.data.rentec.properties.filter(function(
    singleProperty
  ) {
    return singleProperty.property_id === props.pageContext.id;
  });

  const propertyPre = propertyArr[0];

  const property = setPropertyData(propertyPre, props.pageContext.location);

  return <PropertyTemplate data={property} context={props} />;
};

export default Property;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import GoogleMapReact from 'google-map-react';
import { rgba } from 'polished';
import { useStaticQuery, graphql } from 'gatsby';
import Lightbox from 'react-image-lightbox';

import Layout from '@common/Layout';
import CTA1 from '@sections/CTA1';
import Navbar from '@common/Navbar';
import Footer from '@sections/Footer';
import ExternalLink from '@common/ExternalLink';
import { Section, Container } from '@components/global';
import { ReactComponent as Bedrooms } from '@static/icons/beds.svg';
import { ReactComponent as Bathrooms } from '@static/icons/bathrooms.svg';
import { ReactComponent as ZoomIn } from '@static/icons/zoom-in.svg';
import { ReactComponent as Area } from '@static/icons/area.svg';
import starUrl, { ReactComponent as Marker } from '@static/images/marker.svg';
import { Button } from './common/Button';
import { createMapOptions, createMapOptionsSmall } from '../helpers/mapStyles';
import {
  addressFormat,
  address2Format,
  priceFormat,
  detailsArray,
  formatDetail,
} from '../helpers/propertyHelpers';
import BlockContent from './common/BlockContent';

const PropertyTemplate = ({ data, context }) => {
  // React Image Gallery
  const images = [];
  const captions = [];
  let img = {};

  const mapCenter = {
    lat: data.lat,
    lng: data.lng,
  };

  data.images.map((image, i) => {
    if (image) {
      img = {
        source: image,
        caption: `${data.address} - ${data.city} ${data.state}. ${data.zip}`,
      };
      images.push(img.source);
      captions.push(img.caption);
    }
  });

  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [isOpen, toggleIsOpen] = React.useState(false);

  function loadPhoto(index) {
    toggleIsOpen(true);
    setPhotoIndex(index);
  }

  const seo = {
    description: 'test', // TODO
    title: `${addressFormat(data.address, data.nickname)} | Shahid Properties`,
    url: '',
    author: 'Kim Shahid',
    keywords: [
      'Shahid Properties',
      `${addressFormat(data.address, data.nickname)}`,
    ],
    twitter: {
      id: '',
      img: data.images[0],
    },
    facebook: {
      id: '',
      img: data.images[0],
    },
  };

  const isHome = context.location.pathname === '/';

  const sitewide = useStaticQuery(graphql`
    query {
      sanitySitewideSettings {
        _rawTextRental
      }
    }
  `);

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => toggleIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          imageCaption={captions[photoIndex]}
        />
      )}
      <Layout seo={seo}>
        <PropertyTemplateWrap>
          <Navbar isHome={isHome} />
          <Section>
            <Container>
              <PropertyGrid>
                <MainInfo>
                  <h1>{addressFormat(data.address, data.nickname)}</h1>
                  <span className="city-state">
                    {address2Format(data.city, data.state, data.zip)}
                  </span>
                  <span className="price">{priceFormat(data.rent)}</span>
                  <PhotoGrid single="true">
                    {data.images[0] && (
                      <figure onClick={() => loadPhoto(0)}>
                        <ImageCover>
                          <ZoomIn />
                        </ImageCover>
                        <img src={data.images[0]} alt={data.address} />
                      </figure>
                    )}
                  </PhotoGrid>
                  <ButtonsGrid columns={2}>
                    {data.app_link && (
                      <ButtonExternal
                        hasshadow="true"
                        color="primary"
                        href={data.app_link}
                      >
                        Apply Now
                      </ButtonExternal>
                    )}
                    <ButtonExternal
                      color="black"
                      ghost="true"
                      href={`mailto:rentals@shahidproperties.com?Subject=Website Inquiry: ${addressFormat(
                        data.address,
                        data.nickname
                      )}`}
                    >
                      Ask a question
                    </ButtonExternal>
                  </ButtonsGrid>
                  {(data.bedrooms || data.bathrooms || data.sqft) && (
                    <InfoHighlights>
                      {data.bedrooms && (
                        <div>
                          <span>Bedrooms:</span>
                          <div>
                            <span>{data.bedrooms}</span>
                            <Bedrooms />
                          </div>
                        </div>
                      )}
                      {data.bathrooms && (
                        <div>
                          <span>Bathrooms:</span>
                          <div>
                            <span>{data.bathrooms}</span>
                            <Bathrooms />
                          </div>
                        </div>
                      )}
                      {data.sqft && (
                        <div>
                          <span>Area:</span>
                          <div>
                            <span>{data.sqft} sq. ft</span>
                            <Area />
                          </div>
                        </div>
                      )}
                    </InfoHighlights>
                  )}
                  <Info>
                    {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                    <p>{data.class_ad}</p>
                    <Amenities columns={3}>
                      {detailsArray.map((detail) => {
                        if (data[detail]) {
                          return (
                            <div key={detail}>
                              <span>{formatDetail(detail)}:</span>
                              <span>{data[detail]}</span>
                            </div>
                          );
                        }
                      })}
                    </Amenities>
                  </Info>
                  {data.lat && data.lng && (
                    <Map>
                      <GoogleMapWrap>
                        <div>
                          <GoogleMap
                            bootstrapURLKeys={{
                              key: 'AIzaSyBgG1i8HUFcO15oY1eOhhhoERnVt5O8d5A',
                            }}
                            defaultCenter={mapCenter}
                            defaultZoom={15}
                            options={createMapOptionsSmall}
                          >
                            <MarkerNew
                              lat={data.lat}
                              lng={data.lng}
                              text={data.address && data.address}
                            />
                          </GoogleMap>
                        </div>
                      </GoogleMapWrap>
                    </Map>
                  )}
                </MainInfo>
                <Photos>
                  <PhotoGrid columns={3}>
                    {data.images.map((image, i) => {
                      if (image) {
                        return (
                          <figure onClick={() => loadPhoto(i)} key={image._key}>
                            <ImageCover>
                              <ZoomIn />
                            </ImageCover>
                            <img src={image} alt={data.address} />
                          </figure>
                        );
                      }
                    })}
                  </PhotoGrid>
                </Photos>
              </PropertyGrid>
              {sitewide.sanitySitewideSettings &&
                sitewide.sanitySitewideSettings._rawTextRental && (
                  <CTA1>
                    <BlockContent
                      data={sitewide.sanitySitewideSettings._rawTextRental}
                    />
                  </CTA1>
                )}
            </Container>
          </Section>
          <Footer />
        </PropertyTemplateWrap>
      </Layout>
    </>
  );
};

PropertyTemplate.propTypes = {
  data: PropTypes.any,
  context: PropTypes.any,
};

const PropertyTemplateWrap = styled.section`
  padding-top: 0;
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    padding-top: 80px;
  }
`;

const PropertyGrid = styled.div`
  /* display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'maininfo'
    'photos'
    'info';

  @media (min-width: ${(props) => props.theme.screen.md}) {
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'maininfo photos'
      'info photos';
    align-items: start;
    align-content: start;
    grid-auto-flow: dense;
    grid-template-rows: repeat(auto-fit, minmax(50px, auto));
  } */
  display: flex;
  flex-wrap: wrap;
`;

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: maininfo;
  align-self: start;

  @media (min-width: ${(props) => props.theme.screen.md}) {
    width: 50%;
    padding-right: 50px;
  }

  h1 {
    font-weight: ${(props) => props.theme.font_weight.bold};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: ${(props) => props.theme.font_weight.bold};
    color: ${(props) => props.theme.color.black};
    ${(props) => props.theme.font_size.large};

    @media (min-width: ${(props) => props.theme.screen.sm}) {
      ${(props) => props.theme.font_size.xlarge};
    }
    @media (min-width: ${(props) => props.theme.screen.md}) {
      ${(props) => props.theme.font_size.larger};
    }
    @media (min-width: ${(props) => props.theme.screen.lg}) {
      ${(props) => props.theme.font_size.xlarge};
    }
  }

  .city-state {
    font-family: ${(props) => props.theme.font.primary};
    font-weight: ${(props) => props.theme.font_weight.bold};
    color: ${(props) => props.theme.color.black};
    ${(props) => props.theme.font_size.regular};

    @media (min-width: ${(props) => props.theme.screen.sm}) {
      ${(props) => props.theme.font_size.larger};
    }
    @media (min-width: ${(props) => props.theme.screen.md}) {
      ${(props) => props.theme.font_size.large};
    }
    @media (min-width: ${(props) => props.theme.screen.lg}) {
      ${(props) => props.theme.font_size.larger};
    }
  }

  .price {
    color: ${(props) => props.theme.color.primary};
    font-weight: ${(props) => props.theme.font_weight.medium};
    padding: 10px 0 0;
    ${(props) => props.theme.font_size.regular};

    @media (min-width: ${(props) => props.theme.screen.sm}) {
      ${(props) => props.theme.font_size.larger};
    }
    @media (min-width: ${(props) => props.theme.screen.md}) {
      ${(props) => props.theme.font_size.large};
    }
    @media (min-width: ${(props) => props.theme.screen.lg}) {
      ${(props) => props.theme.font_size.larger};
    }
  }
`;

const InfoHighlights = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  padding: 50px 0;
  margin: 50px 0 0;
  border-top: solid 1px ${(props) => props.theme.color.black_light};
  border-bottom: solid 1px ${(props) => props.theme.color.black_light};
  grid-gap: 20px;

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: repeat(3, auto);
  }

  & > div {
    display: grid;
    justify-content: center;
    /* grid-template-columns: auto auto; */
    grid-gap: 20px;
    align-items: center;

    &:nth-child(3) {
      @media (max-width: ${(props) => props.theme.screen.sm}) {
        grid-column: 1 / -1;
      }
    }

    @media (min-width: ${(props) => props.theme.screen.sm}) {
      justify-content: start;
    }

    * {
      @media (min-width: ${(props) => props.theme.screen.sm}) {
        justify-self: start;
      }
    }

    span:nth-child(1) {
      ${(props) => props.theme.font_size.regular};
      text-align: center;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 25px;
        font-weight: ${(props) => props.theme.font_weight.bold};
        color: ${(props) => props.theme.color.black};
      }

      svg {
        margin-left: 20px;
        path {
          fill: ${(props) => rgba(props.theme.color.black, 0.4)};
        }
      }
    }
  }
`;

const ImageCover = styled.div`
  background-color: ${(props) => rgba(props.theme.color.black, 0.3)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
  ${(props) => props.theme.transition};

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    ${(props) => props.theme.transition};

    path,
    circle {
      stroke: ${(props) => props.theme.color.white};
    }
  }
`;

const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-gap: 10px;
  margin-top: 20px;

  @media (min-width: ${(props) => props.theme.screen.md}) {
    display: ${(p) => (p.single === 'true' ? 'none' : 'grid')};
    margin-top: 0;
    grid-gap: 20px;
  }

  figure {
    width: 100%;
    height: 0;
    display: block;
    padding-top: calc(232 / 333 * 100%);
    position: relative;
    cursor: pointer;
    border-radius: 3px;
    /* overflow: hidden; */
    ${(props) => props.theme.transition};

    &:first-child {
      @media (min-width: ${(props) => props.theme.screen.md}) {
        grid-column: span ${(props) => props.columns};
      }
    }

    &:after {
      content: '';
      width: 90%;
      height: 100%;
      position: absolute;
      box-shadow: 0px 0 0 rgba(0, 0, 0, 0);
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 2px;
      ${(props) => props.theme.transition};
      box-shadow: 0px 0 0 rgba(0, 0, 0, 0);
    }

    &:hover {
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
      ${ImageCover} {
        opacity: 1;

        svg {
        }
      }
      img {
        /* transform: scale(1.02); */
      }
      &:after {
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
        /* border: 5px solid ${(props) => props.theme.color.white}; */
      }
    }

    img,
    .gatsby-image-wrapper {
      position: absolute !important;
      /* border: 5px solid ${(props) => props.theme.color.white}; */
      top: 0 !important;
      left: 0 !important;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 2;
      border-radius: 3px;
      ${(props) => props.theme.transition};
    }
  }
`;

const Photos = styled.div`
  grid-area: photos;
  align-self: start;
  width: 100%;
  margin-top: 50px;

  @media (min-width: ${(props) => props.theme.screen.md}) {
    width: 50%;
    margin-top: 0;
  }
`;

const Amenities = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-gap: 30px 10px;
  margin-top: 50px;

  div {
    display: flex;
    flex-direction: column;

    span:first-child {
      font-weight: ${(props) => props.theme.font_weight.bold};
      color: ${(props) => props.theme.color.black};
      margin-bottom: 15px;
    }
  }
`;

const Info = styled.div`
  grid-area: info;
  align-self: start;
  margin-top: 40px;

  @media (min-width: ${(props) => props.theme.screen.md}) {
    /* margin-top: 0; */
  }

  p:first-child {
    padding-top: 0;
    margin-top: 0;
  }
`;

const Map = styled.div`
  grid-area: map;
  margin-top: 50px;
`;

const GoogleMapWrap = styled.div`
  height: 0;
  padding-top: calc((440 / 575) * 100%);
  position: relative;

  > div {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const GoogleMap = styled(GoogleMapReact)`
  height: 100%;
  width: 100%;
  z-index: 0;
`;

const ButtonsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-top: 20px;

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  }

  a:nth-child(2) {
    margin-left: 0;
  }
`;

const MarkerNew = (props) => <Marker />;

const ButtonExternal = styled(Button.withComponent(ExternalLink))``;

export default withTheme(PropertyTemplate);
